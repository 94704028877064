import apilogroboweb from '@/services/api-recebimentoweb';
import axios from 'axios';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apilogroboweb.get('logrobo', { params: { ...where, ...params } }),
      ]);

      return { data, total: data.length };
    },

    async findImagens(_, { rota }) {
      let possuiErro = null;
      const [{ data }] = await Promise.all([
        axios({
          method: 'get',
          url: `${rota}/imagem/all`,
        }),
      ]).catch(() => {
        possuiErro = true;
      });

      if (possuiErro) {
        return null;
      }
      return { data };
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apilogroboweb.get('logrobo');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apilogroboweb.get(`logrobo/${id}`);
      return data;
    },

    async create(_, dados) {
      const [{ data }] = await Promise.all([
        apilogroboweb.post('logrobo/create', dados),
      ]);

      return data;
    },

    async delete(_, dados) {
      const [{ data }] = await Promise.all([
        apilogroboweb.delete(`logrobo/delete/${dados.id}`, dados),
      ]);

      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
