export const ROUTE_HOME = {
  path: '/',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_LOGROBO = {
  path: '/logrobo',
  name: 'logrobo',
};

export const ROUTE_LOGROBO_ID = {
  path: '/logrobo/:logroboid',
  name: 'logrobo.id',
};

export const ROUTE_ACESSO = {
  path: '/acesso',
  name: 'acesso',
};

export const ROUTE_ACESSO_ID = {
  path: '/acesso/acessoid',
  name: 'acessoid',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_CADASTRO_EMPRESA = {
  path: '/cadastroempresa',
  name: 'cadastroempresa',
};

export const ROUTE_CLIENTE = {
  path: '/cliente',
  name: 'cliente',
};
