import apilogroboweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apilogroboweb.get('controleacesso', { params: { ...where, ...params } }),
      ]);

      return { data, total: data.length };
    },

    async create(_, dados) {
      const [{ data }] = await Promise.all([
        apilogroboweb.post('controleacesso', dados),
      ]);

      console.log(dados, 'dados');

      return data;
    },

    async delete(_, idnuvem) {
      const [{ data }] = await Promise.all([
        apilogroboweb.delete(`controleacesso/${idnuvem}`),
      ]);

      return data;
    },

    async update(_, dados) {
      const [{ data }] = await Promise.all([
        apilogroboweb.put(`controleacesso/${dados.id}`, dados),
      ]);

      return data;
    },

    async findComEmpresa(_, { options }) {
      const data = await Promise.all([
        apilogroboweb.get('controleacesso/empresa', { params: options }),
      ]);

      return data[0].data;
    },

    async findComEmpresaId(_, { id }) {
      const data = await Promise.all([
        apilogroboweb.get(`controleacesso/empresa/${id}`),
      ]);

      return data[0].data;
    },

    // async findImagens(_, { rota }) {
    //   console.log(rota);
    //   const [{ data }] = await Promise.all([
    //     axios({
    //       method: 'get',
    //       url: `${rota}/imagem/all`,
    //     }),
    //   ]);

    //   console.log(data);

    //   return { data, total: data.length };
    // },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apilogroboweb.get('controleacesso');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apilogroboweb.get(`controleacesso/${id}`);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
