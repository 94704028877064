export const TOKEN = 'auth:token';

export const LOGROBO_STORAGE = {
  page: 'lotes:page',
  sort: 'lotes:sort',
  search: 'lotes:search',
  indexradio: 'logrobo:indexradio',
  item: 'logrobo:item',
};

export const ACESSO_STORAGE = {
  page: 'lotes:page',
  sort: 'lotes:sort',
  search: 'lotes:search',
  indexradio: 'acesso:indexradio',
  item: 'acesso:item',
};

export const USER_STORAGE = {
  userId: 'id-usuario',
  cpfusuario: 'cpfusuario',
};
