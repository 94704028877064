import Vue from 'vue';
import VueRouter from 'vue-router';

import vuetify from '@/plugins/vuetify';

import * as ROUTES from '@/constants/ROUTES';

import {
  layoutRoute, viewRoute, redirectRoute, requireAuthenticated, requireUnauthenticated,
} from '../utils/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    } else if (to.name === from.name) {
      scrollTo = document.documentElement.scrollTop;
    }

    return vuetify.framework.goTo(scrollTo);
  },

  routes: [
    layoutRoute(ROUTES.ROUTE_SIGN_IN.path, 'base', [
      viewRoute({
        name: ROUTES.ROUTE_SIGN_IN.name,
        view: 'sign-in',
      }, requireUnauthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_HOME.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_HOME.name,
        view: 'home',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LOGROBO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LOGROBO.name,
        appBar: 'AppBar',
        view: 'logrobo',
        filters: 'Filters',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LOGROBO_ID.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LOGROBO_ID.name,
        appBar: 'AppBar',
        view: 'logrobo_id',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_ACESSO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_ACESSO.name,
        appBar: 'AppBar',
        view: 'acesso',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_ACESSO_ID.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_ACESSO_ID.name,
        appBar: 'AppBar',
        view: 'acesso_id',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CADASTRO_EMPRESA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CADASTRO_EMPRESA.name,
        appBar: 'AppBar',
        view: 'empresa',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CLIENTE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CLIENTE.name,
        appBar: 'AppBar',
        view: 'cliente',
      }, requireAuthenticated),
    ]),

    // layoutRoute(ROUTES.ROUTE_LOTES_ID.path, 'default', [
    //   viewRoute({
    //     name: ROUTES.ROUTE_LOTES_ID.name,
    //     view: 'lotes_id',
    //     appBar: 'AppBar',
    //   }, requireAuthenticated),
    // ]),

    redirectRoute(ROUTES.ROUTE_HOME),
  ],
});

export default router;
