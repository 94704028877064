import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import logrobo from './modules/logrobo';
import empresa from './modules/empresa';
import acesso from './modules/acesso';
import cliente from './modules/cliente';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    logrobo,
    empresa,
    acesso,
    cliente,
  },
});
